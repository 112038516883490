import React, { useState, useEffect } from "react";
import { Link } from "@StarberryUtils";
import { Button, Form, Container, Row, Col, Fade } from 'react-bootstrap';
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Breadcrumbs from "../components/Footer/Breadcrumb/Breadcrumb";

import { inViewOptions, containerVariants, fadeInFromBottom } from '../components/Utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

// markup
const NotFoundPage = () => {
    const [showMenu, setShowMenu] = useState(false);
    const [render, setRender] = useState(false);
    const handlerClick = () => {
        setShowMenu(!showMenu)
        var root = document.getElementsByTagName('html')[0];
        if (showMenu == true) {
            document.body.className = ''; 
            root.className = ''; 
        } else {
            document.body.classList.add("overflow-hidden");   
            root.classList.add("overflow-hidden");  
        }
    }
    const [showSearch, setShowSearch] = useState(false);
    const handlerSearchClick = () => {
        setShowSearch(!showSearch)
        setShowMenu(false)
        if (showSearch == true) {
            document.body.className = '';
        } else {
            document.body.classList.add("overflow-hidden");
        }
    }
    useEffect(() => {
        setRender(true)
    })

    return (
        <InView {...inViewOptions}>
            {({ ref, inView }) => (
                <motion.div
                    className={(showMenu || showSearch) ? "wrapper open-search-block header-style page-not-found" : "wrapper header-style page-not-found"}
                    ref={ref}
                    initial="hidden"
                    animate={inView ? "visible" : "hidden"}
                    variants={containerVariants}
                >
                    {render &&
                        <>
                            <Header showMenu={showMenu} handlerClick={handlerClick} handlerSearchClick={handlerSearchClick} showSearch={showSearch} fixed={true} />
                            <motion.div variants={fadeInFromBottom}>
                                <div className="banner-section notfound-page">
                                    <Container>
                                        <Row>
                                            <Col lg="2" ></Col>
                                            <Col lg="8" >
                                                <div className="banner-section-content">
                                                    <h1>We’re sorry, the page you were looking for cannot be found.</h1>
                                                    <p>This maybe because it has been removed, we’ve changed its name or it is temporarily unavailable.</p>
                                                </div>
                                                <div className="banner-section-btn">
                                                    <Link to={`/property/for-sale`} className="btn btn-primary">Property Search<i className="icon-arrow"></i></Link>
                                                    <Link to={`/property-valuation`} className="btn btn-primary">Value Your Property<i className="icon-arrow"></i></Link>
                                                </div>
                                            </Col>
                                            <Col lg="2" ></Col>
                                        </Row>
                                    </Container>
                                </div>
                            </motion.div>
                            <Breadcrumbs tag={null} category={null} subCategory={null} data={''} page={404} />
                            <Footer />
                        </>
                    }
                </motion.div>
            )}
        </InView>
    )
}

export default NotFoundPage
